import * as React from 'react'
import css from './ShareModal.scss'
import Modal from '../../../Common/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { faPinterestP } from '@fortawesome/free-brands-svg-icons'
import { useState } from 'react'
import { IProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Interfaces/Strapi/Product'

export interface IShareModalProps {
  onClose: () => void
  product: IProduct
}

const ShareModal = ({ onClose, product }: IShareModalProps) => {
  const pageUrl = encodeURIComponent(window.location.href)
  const [linkCopied, setLinkCopied] = useState(false)
  const [showAddressTo, setShowAddressTo] = useState(false)
  const [addressTo, setAddressTo] = useState('')
  const [customMessage, setCustomMessage] = useState('')

  const handleCopy = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const input = document.getElementById('shareURL') as any

    if (input) {
      input.select()
      navigator.clipboard.writeText(input.value).then(() => setLinkCopied(true))
    }
  }

  const handlePrint = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    onClose()
    setTimeout(() => {
      window.print()
    }, 1)
  }

  return (
    <Modal onClose={onClose} width="500px" isCloseTextBold={true} isTitleBackgroundGrey={false} isOverFlowing={true}>
      <h2 className={css.title}>Share this item</h2>
      <div className={css.body}>
        <a className={css.shareBox} onClick={() => setShowAddressTo(true)}>
          <FontAwesomeIcon icon={faEnvelope} className={css.icon} />
          Email
        </a>
        {showAddressTo && (
          <div className={css.shareEmail}>
            <div>
              <input
                autoFocus
                placeholder="Address To"
                onChange={e => setAddressTo(e.target.value)}
                className={css.shareInput}
                id="addressTo"
                value={addressTo}
                aria-label="Text field"
                data-uw-hidden-control="hidden-control-element"
              />
              <textarea
                className={css.shareText}
                placeholder="Add a custom message (optional)..."
                value={customMessage}
                onChange={e => setCustomMessage(e.target.value)}
                rows={4}
              />
            </div>
            <a
              onClick={onClose}
              href={`mailto:${addressTo}?subject=Check%20this%20out!%20-%20${
                product.name
              }&body=${pageUrl}%0A%0A${encodeURIComponent(customMessage)}`}
            >
              Send
            </a>
          </div>
        )}
        <a className={css.shareBox} data-clipboard-target="#shareURL" onClick={handleCopy}>
          <FontAwesomeIcon icon={faCopy} className={css.icon} />
          {linkCopied ? 'Link Copied!' : 'Copy Link'}
        </a>
        <input
          className={css.shareInput}
          id="shareURL"
          value={window.location.href}
          readOnly
          aria-label="Text field"
          data-uw-hidden-control="hidden-control-element"
        />
        <a className={css.shareBox} href="" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} className={css.icon} />
          Print
        </a>
        <a
          onClick={onClose}
          className={css.shareBox}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://pinterest.com/pin/create/button/?url=${window.location.href}&media=${product.thumbnail}&description=${product.name}`}
        >
          <FontAwesomeIcon icon={faPinterestP} className={css.icon} />
          Pinterest
        </a>
      </div>
    </Modal>
  )
}

export default ShareModal
